/* global treeState */
import { nameOrDefault } from './utils';

export const DEFAULT_FILTER = {
  tags: [],
  withContent: false,
  withComments: false,
  externalUrl: false,
  urlChanged: false,
};

/**
 * Check if the given filter represents an non empty value (need to filter nodes)
 *
 * @param {*} filter - filter object
 * @returns boolean
 */
export const isNotEmptyFilter = (value) => {
  return (
    value && (value.tags?.length || value.withContent || value.withComments || value.externalUrl || value.urlChanged)
  );
};

/**
 * Check if the given filter represents an empty value (no need to filter nodes)
 *
 * @param {*} filter - filter object
 * @returns boolean
 */
export const isEmptyFilter = (value) => !isNotEmptyFilter(value);

const contentMatcher = (withContent) => (n) => withContent && n.data.withContent != null;
const commentsMatcher = (withComments) => (n) => withComments && treeState.hasComments(n);
const externalMatcher = (externalUrl) => (node) => externalUrl && Boolean(node.data.external);
const urlChangedMatcher = (urlChanged) => (node) => urlChanged && Boolean(node.data.urlChanged);

const tagsMatcher = (idList) => {
  const noTags = !idList || idList.length === 0;

  return (n) => {
    if (noTags) {
      return false;
    }

    const { tags } = n.data;

    if (!tags) {
      return false;
    }

    return idList.some((tagId) => tags.some((t) => t.id === tagId));
  };
};

/**
 * build a function that given a node, returns true if the node matches the given filter
 * @param {*} filter - filter
 * @returns function
 */
export const filterMatcher = (filter) => {
  if (!filter || isEmptyFilter(filter)) {
    return () => true;
  }

  const { tags, withContent, withComments, externalUrl, urlChanged } = filter;

  const matchTags = tagsMatcher(tags);
  const matchContent = contentMatcher(withContent);
  const matchComments = commentsMatcher(withComments);
  const matchExternal = externalMatcher(externalUrl);
  const matchUrlChanged = urlChangedMatcher(urlChanged);

  return (n) => matchContent(n) || matchTags(n) || matchComments(n) || matchExternal(n) || matchUrlChanged(n);
};

export const nameMatcher = (term) => {
  const searchTerm = term.toLowerCase();

  return (n) => {
    const { isFolder } = n.data;

    if (isFolder) {
      return false;
    }

    if (!term) {
      return true;
    }

    const title = nameOrDefault(n.data);

    return title.toLowerCase().includes(searchTerm);
  };
};
