import { get, patch, post, parsedErrors } from '../../common/request';

export function getPageContent(sitemapId, pageId) {
  const url = `/api/v1/sitemaps/${sitemapId}/pages/${pageId}/content`;

  return get(url)
    .catch((err) => {
      if (err.status === 404 || err.status === 403) {
        return null;
      }

      throw err;
    })
    .catch(parsedErrors);
}

export function updatePageContent(sitemapId, pageId, userId, pageContent) {
  const url = `/api/v1/sitemaps/${sitemapId}/pages/${pageId}/content`;

  return patch(url, { data: { page_content: pageContent, done_by: userId } }).catch(parsedErrors);
}

export function addEditorFiles(sitemapId, pageId, newEditorFiles) {
  const url = `/api/v1/sitemaps/${sitemapId}/pages/${pageId}/content_editor_files`;

  return patch(url, { data: { new_editor_files: newEditorFiles } }).catch(parsedErrors);
}

export function clonePageContents(sitemapId, pageContentsToClone, userId) {
  const url = `/api/v1/sitemaps/${sitemapId}/pages/clone`;

  return post(url, { data: { page_contents_to_clone: pageContentsToClone, done_by: userId } }).catch(parsedErrors);
}
