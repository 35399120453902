export const shouldOpenInNewTab = () => {
  return window.parent && window.parent !== window;
};

/**
 * Go to location. Consider embedded mode.
 *
 * @param {*} href - URL to navigate to
 * @returns window opened reference or null
 */
export const navigateTo = (href) => {
  if (shouldOpenInNewTab()) {
    return window.open(href, '_blank', 'noopener noreferrer');
  }

  window.location.href = href;

  return null;
};
