export default {
  SCREENSHOT_PATH: import.meta.env.VITE_SCREENSHOT_PATH,
  DEFAULT_SCREENSHOT: import.meta.env.VITE_DEFAULT_SCREENSHOT || '',
  TREE_COLLAPSE_THRESHOLD:
    import.meta.env.VITE_TREE_COLLAPSE_THRESHOLD && parseInt(import.meta.env.VITE_TREE_COLLAPSE_THRESHOLD, 10),
  TREE_EXPAND_LIMIT: import.meta.env.VITE_TREE_EXPAND_LIMIT && parseInt(import.meta.env.VITE_TREE_EXPAND_LIMIT, 10),
  NODE_TAGS_LIMIT: parseInt(import.meta.env.VITE_NODE_TAGS_LIMIT || '10', 10),
  // Do not upload a file when exceeds this size
  PAGE_MAX_FILE_UPLOAD_SIZE: parseInt(import.meta.env.VITE_PAGE_MAX_FILE_UPLOAD_SIZE || `${10 * 1024 * 1024}`, 10),
  // Do not create a preview when file exceeds this size
  PAGE_MAX_UPLOAD_PREVIEW_FILE_SIZE: parseInt(
    import.meta.env.VITE_PAGE_MAX_UPLOAD_PREVIEW_FILE_SIZE || `${512 * 1024}`,
    10
  ),
  PAGE_MAX_FILES: parseInt(import.meta.env.VITE_PAGE_MAX_FILES || '20', 10),
  TINYMCE_API_KEY: import.meta.env.VITE_TINYMCE_API_KEY || '',
  USER_FLOWS_URL: import.meta.env.VITE_USER_FLOWS_URL,
  GOOGLE_CLIENT_ID: import.meta.env.VITE_GOOGLE_CLIENT_ID || '',
  GOOGLE_API_KEY: import.meta.env.VITE_GOOGLE_API_KEY || '',
  GOOGLE_APP_ID: import.meta.env.VITE_GOOGLE_APP_ID || '',
};
