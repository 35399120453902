import Color from 'color';

export const createColor = (background) => {
  if (background instanceof Color) {
    return background;
  }

  return Color(background);
};

export const colorContrast = (background) => {
  const color = createColor(background);

  if (color.isDark()) {
    return 'white';
  }
  return '#292b2c';
};
