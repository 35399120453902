import { patch, post, destroy, get } from '../../../common/request';

export const moveComment = async (commentId, { x, y }, user) => {
  return patch(`/api/v1/comments/${commentId}/move`, { data: { comment: { x, y }, done_by: user.random_uuid } });
};

export const createComment = async (comment, user) => {
  return post(`/api/v1/comments`, {
    data: { comment: { ...comment }, done_by: user.random_uuid },
  });
};

export const changeCommentType = async (commentId, newType, user) => {
  return patch(`/api/v1/comments/${commentId}/change_type`, {
    data: { comment: { comment_type: newType }, done_by: user.random_uuid },
  });
};

export const resolveComment = async (commentId, user) => {
  return post(`/api/v1/comments/${commentId}/resolve`, { data: { done_by: user.random_uuid } });
};

export const unresolveComment = async (commentId, user) => {
  return post(`/api/v1/comments/${commentId}/unresolve`, { data: { done_by: user.random_uuid } });
};

export const markCommentAsRead = async (commentId, user) => {
  return post(`/api/v1/comments/${commentId}/mark_as_read`, { data: { done_by: user.random_uuid } });
};

export const updateComment = async (commentId, comment, user) => {
  return patch(`/api/v1/comments/${commentId}`, {
    data: { comment: { ...comment }, done_by: user.random_uuid },
  });
};

export const deleteComment = async (commentId, user) => {
  return destroy(`/api/v1/comments/${commentId}?done_by=${user.random_uuid}`);
};

export const getComments = async (sitemapId, pageId) => {
  let url = `/api/v1/comments?sitemap_id=${sitemapId}`;
  if (pageId) {
    url += `&page_id=${pageId}`;
  }

  return get(url);
};

export const getTeamMembers = async (sitemapId) => {
  return get(`/api/v1/sitemaps/${sitemapId}/team/members`);
};

export const existsComments = async (sitemapId, pageId) => {
  const url = `/api/v1/comments/exists?sitemap_id=${sitemapId}&page_id=${pageId}`;

  return get(url);
};
