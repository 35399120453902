/* eslint-disable import/prefer-default-export */
/* global $ */

export const showAlert = (content, { container = '.alert-container', id } = {}) => {
  const $container = $(container);

  // Don't add alerts with unique identifier twice
  if (id && $container.find(`#${id}`).length > 0) {
    return;
  }

  const idAttr = id ? `id="${id}"` : '';

  $container.append(`
    <div class="alert alert-warning alert-dismissible fade show" ${idAttr} role="alert">
      <button class="close" type="button" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
      ${content}
    </div>
  `);

  const newAlert = $container.find('.alert:last-child');

  $container.trigger({ type: 'shown.alert', alert: newAlert });
};
