import config from '../../config';

// css selector for SVG container
export const treeContainer = '#tree-container';

export const fileUploader = 'tree-screenshot-upload';

// Node image config
const image = {
  width: 312,
  height: 232,
  border: 2,
};

// Image container (image + borders).
// The value can be derivated from this two variables but a lot
// of code use ImageContainer.width/height so we keep the config variable.
const imageContainer = {
  width: image.width + image.border * 2,
  height: image.height + image.border * 2,
};

// Node top circle radio
const topCircle = 4;

// Space for separation
export const nodeSeparation = 52;

// Page caption styles. Just the styles that have something to do with height.
export const captionStyles = {
  fontFamily: "'Overpass Mono', sans-serif",
  fontSize: 24, // Size in pixels
  lineHeight: 1.5,
  fontWeight: 700,
  textTransform: 'capitalize',
  paddingX: 13,
  paddingY: 8,
  overflowWrap: 'anywhere',
};

// Folder text styles. Just the styles that have something to do with height.
export const folderTextStyles = {
  fontFamily: "'Overpass Mono', sans-serif",
  fontSize: 26, // Size in pixels
  lineHeight: 1.5,
  fontWeight: 700,
  paddingX: 13,
  paddingY: 0,
  textAlign: 'center',
  overflowWrap: 'anywhere',
};

const captionLine = {
  maxLength: 19,
  // Line height in pixels.
  height: captionStyles.fontSize * captionStyles.lineHeight,
  // Total padding applied is this value * 2
  paddingY: captionStyles.paddingY,
};

const folderText = {
  maxLength: 17,
  height: folderTextStyles.fontSize * folderTextStyles.lineHeight,
  paddingY: folderTextStyles.paddingY,
};

const tagSquare = {
  size: 40,
  fontSize: 20,
  margin: 10, // Add 10 pixels to container to prevent box-shadow cropping
};

const tagBall = {
  size: 40,
  margin: 10, // Add 10 pixels to container to prevent box-shadow cropping
};

// Configuration to draw nodes (pages & folders)
export const dimensions = {
  node: [
    imageContainer.width,
    // Min-Height Asume only one line of text for title
    imageContainer.height + topCircle + captionLine.height + captionLine.paddingY * 2,
    // Max-Height
    imageContainer.height + topCircle + captionLine.height * 2 + captionLine.paddingY * 2,
  ],
  image,
  imageContainer,
  captionLine,
  folderText,
  topCircle,
  // Action icons size (expand, add children, add sibling). They are square
  actionIcon: captionLine.height + captionLine.paddingY * 2,
  toggleIcon: 40,
  // Type chooser tray (for add children / add sibling) dimensions
  typeChooser: {
    width: (2 * 56 + 2 * 2) * 1.5, // (2 * icons width, 2 * border)
    height: (47 + 2 * 2) * 1.5, // (icon height + 2 * border)
    margin: 20,
  },
  tagBall,
  tagSquare,
  selectIcon: {
    extraMargin: 30, // extend the click area to the left and bottom so user can select without opening node screenshot
  },
};

// Duration of any transition (in miliseconds)
export const duration = 750;

// Tree level height
export const levelHeight = imageContainer.height * 1.8;

export const zoom = {
  scaleExtent: [0.03, 3],
  defaultLevel: 0.5,
};

export const pan = {
  speed: 10,
  boundry: 20, // Within 20px from edges will pan when dragging.
};

/**
 * Sets the maximum distance that the mouse can move between mousedown and mouseup
 * that will trigger a subsequent click event. If at any point between mousedown and mouseup
 * the mouse is greater than or equal to distance from its position on mousedown, the click event
 * following mouseup will be suppressed.
 * The distance is measured in client coordinates (event.clientX and event.clientY).
 *
 * see https://github.com/d3/d3-drag#drag_clickDistance
 */
export const dragClickDistance = 5;

/**
 * Sets the minimum distance that the mouse must move since mousedown to trigger node-drag start event.
 * node-drag will start when mouse is greater than or equals to distance from its position on mousedown.
 * The distance is measured in client coordinates (event.clientX and event.clientY).
 *
 * Node-drag behaviour hides elements, like action buttons. Moving a few pixels while clicking can lead to
 * unexpected results (click triggered on other element).
 * This setting allow to add some tolerance, it defers drag start after mouse moved N pixels (client coordinates,
 * not affected by current zoom)
 */
export const dragStartDistance = dragClickDistance + 1;

export const defaultScreenshot = config.DEFAULT_SCREENSHOT;

export const defaultTitle = '< title missing >';
