/* global $, treeState */

/**
 * We use bootstrap modals with jquery to prompt.
 * Modal is expected to be present on the page and include one form.
 *
 * This function returns a promise that is resolved when user
 * submit the form
 *
 * @param {string} selector - modal selector
 * @param {object} options - options
 * @param {function} options.beforeShow - function to be called before showing the modal
 * @param {function} options.transformResult - function to call when modal is submitted. proptModal resolves to the returned value of this function
 * @param {function} options.customValidations - custom validations to be added to the form validator. See https://1000hz.github.io/bootstrap-validator/#validator-options
 * @param {function} options.afterHide - function to be called after hidding the modal
 */
function promptModal(selector, { beforeShow, transformResult, customValidations = {}, afterHide = () => {} } = {}) {
  return new Promise((resolve) => {
    const modalContainer = $(selector);

    // Prepare modal

    // Load initial values
    beforeShow(modalContainer);

    // Validat form and assign submit handler
    modalContainer
      .find('form')
      .validator({ html: true, custom: customValidations })
      .on('submit', ($event) => {
        // stop if form is invalid
        if ($event.isDefaultPrevented()) {
          return;
        }

        // Prevent default submit
        $event.preventDefault();

        // Tell caller that we have a new title
        resolve(transformResult(modalContainer));
        // Close the modal
        modalContainer.modal('hide');
      });

    // Remove registered handlers when modal is closed
    modalContainer.on('hidden.bs.modal', () => {
      modalContainer.find('form').validator('destroy').off('submit');
      afterHide(modalContainer);
    });

    modalContainer.modal();
  });
}

/**
 * We use bootstrap modals with jquery to prompt.
 * Modal is expected to be present on the page.
 *
 * This function returns a promise that is resolved when user
 * enter a new value for the prompt
 */
export const promptNewTitle = (title) => {
  return promptModal('#treeNodeTitlePrompt', {
    beforeShow(modalContainer) {
      modalContainer.find('.new_title').val(title);
    },
    transformResult(modalContainer) {
      return modalContainer.find('.new_title').val().trim();
    },
  });
};

/**
 * We use bootstrap modals with jquery to prompt.
 * Modal is expected to be present on the page.
 *
 * This function returns a promise that is resolved when user
 * enter a new value for the prompt
 */
export const promptNewUrl = (url) => {
  const uniquenessValidator = treeState.urlUniquenessValidator({ ignore: url });

  return promptModal('#treeNodeUrlPrompt', {
    customValidations: {
      unique($el) {
        const value = $el.val().trim();

        if (!uniquenessValidator.validate(value)) {
          return 'This URL is already taken';
        }

        return undefined;
      },
    },
    beforeShow(modalContainer) {
      modalContainer.find('.new_url').attr({ 'data-unique': true, placeholder: url }).val(url);
    },
    afterHide(modalContainer) {
      modalContainer.find('.new_url').attr({ 'data-unique': null, placeholder: null });
    },
    transformResult(modalContainer) {
      return modalContainer.find('.new_url').val().trim();
    },
  });
};
