/**
 * Compute file md5 checksum using spark-md5 (https://www.npmjs.com/package/spark-md5)
 *
 * This code is inspired by
 * - https://www.npmjs.com/package/spark-md5#hash-a-file-incrementally
 * - https://github.com/rails/rails/blob/master/activestorage/app/javascript/activestorage/blob_upload.js
 */

import SparkMD5 from 'spark-md5';

const fileSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
const chunkSize = 2097152; // Read in chunks of 2MB

export default function fileChecksum(file) {
  return new Promise((resolve, reject) => {
    const md5Buffer = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();

    const chunkCount = Math.ceil(file.size / chunkSize);
    let chunkIndex = 0;

    const readNextChunk = () => {
      if (chunkIndex < chunkCount || (chunkIndex === 0 && chunkCount === 0)) {
        const start = chunkIndex * chunkSize;
        const end = Math.min(start + chunkSize, file.size);
        const bytes = fileSlice.call(file, start, end);
        fileReader.readAsArrayBuffer(bytes);
        chunkIndex += 1;
        return true;
      }
      return false;
    };

    fileReader.addEventListener('error', () => {
      reject(new Error(`Error reading ${file.name}`));
    });

    fileReader.addEventListener('load', (event) => {
      md5Buffer.append(event.target.result);

      if (!readNextChunk()) {
        const binaryDigest = md5Buffer.end(true);
        const base64digest = btoa(binaryDigest);
        resolve(base64digest);
      }
    });

    readNextChunk();
  });
}
