/* eslint-disable no-underscore-dangle */
/* global openScreenshots $ Sitemap */
import { fileUploader } from './config';
import { promptNewTitle, promptNewUrl } from './modal';

const triggerEvent = (name, data) => {
  document.dispatchEvent(new CustomEvent(name, { detail: data }));
};

/**
 * Wrapper for sitemap edition actions.
 * The interceptor checks if edition is a paid feature. If it is, show a confirm. If not, proceed with the action.
 *
 * Context: we want to expose edition action to end users and push them to upgrade. Try to reach higher conversion.
 *
 * This is not the best implementation but is a fastest way to achieve what we need. Instead of passing props all the
 * way down we use "global context info".
 */
const editionFeatureInterceptor = (fn) => {
  return (event, ...args) => {
    const { editionGuard: guard } = Sitemap || {};

    if (guard && !guard.check()) {
      event.preventDefault();
      event.stopPropagation();

      return;
    }

    fn(event, ...args);
  };
};

export const toggleChildren = (event, d) => {
  event.stopPropagation();
  if (d.children) {
    triggerEvent('treeNodeCollapsed', { target: d });
  } else if (d._children) {
    triggerEvent('treeNodeExpanded', { target: d });
  }
};

export const click = (baseScreenshotPath) => (event, d) => {
  const { isFolder } = d.data;

  if (isFolder) {
    toggleChildren(event, d);
  } else {
    openScreenshots(d, baseScreenshotPath);
  }
};

export const changeParent = (target, newParent, newIndex) => {
  const data = { target: target.data.id, newParent: newParent.data.id, newIndex, parentExpanded: true };

  triggerEvent('treeNodeParentChanged', data);
};

export const addChildren = editionFeatureInterceptor((event, data) => {
  event.stopPropagation();
  const { parent, isFolder = false, index, altParent, altIndex } = data;

  triggerEvent('treeNodeAppendChildren', { parent, isFolder, index, altParent, altIndex });
});

export const cloneNode = editionFeatureInterceptor((event, target) => {
  event.stopPropagation();

  triggerEvent('treeNodeClone', { target });
});

export const deleteNode = editionFeatureInterceptor((event, target) => {
  event.stopPropagation();

  triggerEvent('treeNodeDelete', { target });
});

export const editScreenshot = editionFeatureInterceptor((event, target) => {
  const { id, upload } = target.data;

  // Do not trigger a second upload
  if (upload) {
    event.preventDefault();
    event.stopPropagation();
    return;
  }

  $(`#${fileUploader}`).data('nodeId', id);
});

export const editTitle = editionFeatureInterceptor((event, target) => {
  event.preventDefault();
  event.stopPropagation();

  const { name: title } = target.data;

  promptNewTitle(title).then((newTitle) => {
    // Ignore if new title is empty or the same as original
    if (!newTitle || newTitle === title) {
      return;
    }

    triggerEvent('treeNodeChangeTitle', { target, title: newTitle });
  });
});

const editContent = (event, target) => {
  event.preventDefault();
  event.stopPropagation();

  const { pageId } = target.data;

  triggerEvent('page/EditContent', { pageId, offcanvas: true });
};

export const editNode = (event, target) => {
  const { isFolder } = target.data;

  if (isFolder) {
    editTitle(event, target);
  } else {
    window.sitemapPageContentEditionActionsEnabled ? editContent(event, target) : editScreenshot(event, target);
  }
};

export const toggleSelection = (event, target) => {
  event.stopPropagation();

  triggerEvent('treeNodeToggleSelection', { target });
};

export const editUrl = editionFeatureInterceptor((event, target) => {
  event.preventDefault();
  event.stopPropagation();

  const { url } = target.data;

  promptNewUrl(url).then((newUrl) => {
    // Ignore if new url is empty or the same as original
    if (!newUrl || newUrl === url) {
      return;
    }

    triggerEvent('treeNodeChangeUrl', { target, url: newUrl });
  });
});
