import config from '../config';
import { visit, isCollapsed } from './tree-renderer/hierarchy';

const { TREE_EXPAND_LIMIT } = config;

/**
 * Check if the given tree has the option to expand all nodes.
 * This function consider the existance of visibility limit (max amount of nodes that we can show).
 *
 * Expand can be shown if:
 * - There is one collapsed node (at least)
 * - Visibility limit has not been exceeded
 *
 * Note: the treeSize is required as an argument to avoid an extra tree-walk. We should know the tree size
 *
 * @param {*} root - sitemap tree root node
 * @param {number} treeSize - total number of nodes in the sitemap (collapsed/invisible included)
 */
export const canExpandAll = (root, treeSize) => {
  let hasCollapsed = false;
  let visibleCount = 0;

  // Should be stop as soon as collapsed node is found?
  const stopOnCollapsed = !TREE_EXPAND_LIMIT || treeSize <= TREE_EXPAND_LIMIT;
  // How many nodes should be visible before stop counting
  const visibleLimit = TREE_EXPAND_LIMIT || treeSize;

  // Walk the visible tree.
  visit(
    root,
    (node) => {
      visibleCount += 1;
      hasCollapsed = hasCollapsed || isCollapsed(node);

      if (hasCollapsed && stopOnCollapsed) {
        return true;
      }

      // stop after visiting limit + 1 nodes.
      if (visibleCount > visibleLimit) {
        return false;
      }

      // move to the next node
      return undefined;
    },
    { onlyVisible: true }
  );

  return hasCollapsed && visibleCount < visibleLimit;
};

/**
 * Search for nodes that should be expanded to reach the limit of visibile nodes.
 *
 * Return the collection of ids
 *
 * @param {*} root - sitemap tree instance
 * @param {*} limit - max amount of nodes that can be shown
 */
export const findExpandableNodes = (root, limit) => {
  if (!root || limit <= 1) {
    return [];
  }

  const nodes = [];
  let visible = 1; // Root is always visible

  visit(root, (node) => {
    // eslint-disable-next-line no-underscore-dangle
    const children = node.children || node._children;

    if (children) {
      nodes.push(node.data.id);
      visible += children.length;
    }

    // Stop when we found enought visible nodes
    if (visible >= limit) {
      return true;
    }

    // If there is not enough, continue with next node
    return undefined;
  });

  return nodes;
};
