/* eslint-disable no-underscore-dangle */
/**
 * Helpers to interact with D3 TreeNode hierarchy objects
 */

/**
 * Check if the given node is collapsable or not
 * @param {TreeNode} node
 */
export const isCollapsible = (node) => {
  return !!node.children || !!node._children;
};

/**
 * Check if the given node is collapsed or not
 * @param {TreeNode} node
 */
export const isCollapsed = (node) => {
  return !!node.data.collapsed && isCollapsible(node);
};

/**
 * A recursive helper function for performing some setup by walking through all nodes.
 * Nodes are traversed following breadth-first order.
 * Walk stops when fn something different than undefined and returns that value.
 * By default walk include collapsed nodes. An option can we used to walk only on visible nodes.
 */
export const visit = (root, fn, { onlyVisible = false } = {}) => {
  if (!root || !fn) {
    return;
  }

  const stack = [root];

  while (stack.length > 0) {
    const n = stack.shift();

    const returnValue = fn(n);

    if (returnValue !== undefined) {
      // eslint-disable-next-line consistent-return
      return returnValue;
    }

    const children = n.children || (!onlyVisible && n._children);
    if (children) {
      stack.push(...children);
    }
  }
};

/**
 * Starting from the root, walk down until a node with the given id is found
 *
 * @param {TreeNode} root - node to start searching from
 * @param {*} id - id of the target node
 */
export const findNode = (root, id) => {
  if (!id || !root) {
    return null;
  }

  const matcher = typeof id === 'function' ? id : (n) => n.data.id === id;

  const node = visit(root, (n) => {
    if (matcher(n)) {
      return n;
    }
    return undefined;
  });

  return node === undefined ? null : node;
};

/**
 * Starting from the root, walk down until all nodes with the given ids are found
 *
 * @param {TreeNode} root - node to start searching from
 * @param {*} ids - list of id of the target nodes
 */
export const findNodes = (root, ids) => {
  if (!ids || !root || !Array.isArray(ids)) {
    return [];
  }

  // Optimization for one node
  if (ids.length === 1) {
    const node = findNode(root, ids[0]);
    return node ? [node] : [];
  }

  const result = [];
  const pending = [...ids];
  visit(root, (n) => {
    const idx = pending.indexOf(n.data.id);

    if (idx >= 0) {
      pending.splice(idx, 1);
      result.push(n);
    }

    if (pending.length === 0) {
      // return any value different from undefined. It's just to stop the visit walk.
      return true;
    }

    // return undefined to continue walking
    return undefined;
  });

  return result;
};

/**
 * Invokes the specified function for root and each descendant in breadth-first-order traversal.
 * Return resulting array. Collapsed descendant are included.
 *
 * @param {*} root - Root of the tree
 * @param {*} fn - Function to apply
 */
export const mapTree = (root, fn) => {
  const result = [];

  visit(root, (n) => {
    result.push(fn(n));
  });

  return result;
};

/**
 * Invokes the specified function for root and each descendant in breadth-first-order traversal.
 * Return an array of nodes were invocation returned a truthy value. Collapsed descendant are included.
 *
 * @param {*} root - Root of the tree
 * @param {*} fn - Function to apply
 */
export const filterTree = (root, fn) => {
  const result = [];

  visit(root, (n) => {
    if (fn(n)) {
      result.push(n);
    }
  });

  return result;
};
