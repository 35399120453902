import { throttle } from 'throttle-debounce';

export const jsonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const handleResponse = (response) => {
  if (response.ok) {
    const contentType = response.headers.get('content-type');
    const isJSON = /json|javascript/.test(contentType);

    return Promise.resolve(isJSON ? response.json() : undefined);
  }

  return Promise.reject(response);
};

export const request = (url, options) => {
  const reqOptions = {
    headers: jsonHeaders,
    ...options,
  };

  return fetch(url, reqOptions).then(handleResponse);
};

const [post, patch, put] = ['POST', 'PATCH', 'PUT'].map((method) => {
  return (url, { data, body, ...options } = {}) => {
    return request(url, {
      method,
      body: body || (data && JSON.stringify(data)),
      ...options,
    });
  };
});

export { post, patch, put };

export const get = (url, options) => request(url, options);
export const destroy = (url, options = {}) => request(url, { method: 'DELETE', ...options });

/**
 * Return a function that will execute a GET request only once during throttleWindow.
 * After the throttleWindow, if the function is called again and we don't have a response yet, then
 * previous request is canceled and new one starts.
 */
export const latestThrottledGet = ({
  url,
  onSuccess,
  onError = (err) => {
    throw err;
  },
  throttleWindow = 1000,
}) => {
  // Inspired on Rxjs switchMap operator.
  // Expects a function without arguments that returns a jQuery ajax
  const switchMap = () => {
    let controller = null;

    const handleAbort = (err) => {
      // Do not reject a promise because abort signal was raised
      if (err.name !== 'AbortError') {
        onError(err);
      }
    };

    return () => {
      if (controller?.signal?.aborted === false) {
        controller.abort();
      }
      controller = new AbortController();
      const query = get(url, { signal: controller.signal }).then(onSuccess);
      return query.catch(handleAbort);
    };
  };

  return throttle(throttleWindow, switchMap());
};

const UNEXPECTED_MESSAGE = 'Something went wrong. Please try again later';

export const parsedErrors = async (response) => {
  let data;
  try {
    data = await response.json();
  } catch (e) {
    throw UNEXPECTED_MESSAGE;
  }

  if (data.errors) {
    throw data.errors;
  }

  throw UNEXPECTED_MESSAGE;
};
